import * as React from "react";
import { LongWeekend } from "./Data";
import { DateSpan } from "./DateSpan";

type Props = {
  longWeekends: Array<LongWeekend>;
};

export class Calendar extends React.Component<Props, {}> {
  render() {
    const { longWeekends } = this.props;
    // Render row for each holiday
    const holidayRows = longWeekends.map((longWeekend) => (
      <HolidayRow {...longWeekend} key={longWeekend.start.toISO()} />
    ));
    // Splice in dividers
    const insertPoints = findDividerInserts(longWeekends);
    const rows: Array<React.ReactNode> = [];
    holidayRows.map((hr, i) => {
      rows.push(hr);
      if (insertPoints[i]) {
        rows.push(<DividerRow key={`divider-${i}`} />);
      }
    });
    // Render!
    return (
      <table>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

const HolidayRow = (lw: LongWeekend) => {
  return (
    <tr>
      <td className="DateCol">
        <DateSpan {...lw} month="short" />
      </td>
      <td className="Spacer"></td>
      <td className="NameCol">{lw.name}</td>
    </tr>
  );
};

const DividerRow = () => (
  <tr>
    <td>
      <hr />
    </td>
    <td className="Spacer"></td>
    <td></td>
  </tr>
);

function findDividerInserts(longWeekends: Array<LongWeekend>): Array<boolean> {
  const months = longWeekends.map((lw) => lw.start.month);
  const differences = months.slice(1).map((m, i) => m - months[i]);
  const insertPoints = differences.map((d) => d > 0);
  insertPoints.push(false); // no insert after last element
  return insertPoints;
}
