import { DateTime } from "luxon";

type Props = {
  start: DateTime;
  end: DateTime | null;
  month: "short" | "long";
};

export const DateSpan = ({ start, end, month }: Props) => {
  if (end == null) {
    return <>{start.toFormat("MMM dd")}</>;
  } else if (start.month == end.month) {
    return (
      <>
        {start.toFormat("MMM dd")} &ndash;{" "}
        {end.toLocaleString({
          day: "2-digit",
        })}
      </>
    );
  } else {
    return (
      <>
        {start.toFormat("MMM dd")} &ndash; {end.toFormat("MMM dd")}
      </>
    );
  }
};
