import { DateTime } from "luxon";
import * as React from "react";

import { LongWeekend, nextLongWeekend } from "./Data";

type Props = {
  next: LongWeekend;
};

type State = {
  now: DateTime;
};

export class Countdown extends React.Component<Props, State> {
  state = {
    now: DateTime.now(),
  };

  render() {
    const { next } = this.props;
    setTimeout(() => {
      this.setState(() => ({ now: DateTime.now() }));
    }, 1000);
    const toNext = next.start.diff(this.state.now, [
      "days",
      "hours",
      "minutes",
      "seconds",
    ]);
    return (
      <div className="Countdown">
        <div className="Cell">
          <h2 className="Quantity">{pad2(toNext.days)}</h2>
          <p className="Unit">days</p>
        </div>
        <div className="Cell">
          <h2 className="Quantity">{pad2(toNext.hours)}</h2>
          <p className="Unit">hrs</p>
        </div>
        <div className="Cell">
          <h2 className="Quantity">{pad2(toNext.minutes)}</h2>
          <p className="Unit">min</p>
        </div>
        <div className="Cell">
          <h2 className="Quantity">{pad2(toNext.seconds)}</h2>
          <p className="Unit">sec</p>
        </div>
      </div>
    );
  }
}

function pad2(num: number): string {
  if (num > 99) {
    return Math.floor(num).toString();
  } else {
    return Math.floor(num).toString().padStart(2, "0");
  }
}
