import { DateTime } from "luxon";

export type LongWeekend = {
  // start and end are inclusive
  start: DateTime;
  end: DateTime | null;
  name: string;
};

export function allLongWeekends(): Array<LongWeekend> {
  return HOLIDAYS.map(holidayToLongWeekend);
}

export function nextLongWeekend(
  longWeekends: Array<LongWeekend>
): LongWeekend | null {
  const now = DateTime.now();
  let next = 0;
  while (next < longWeekends.length && longWeekends[next].start < now) {
    next++;
  }
  if (next >= longWeekends.length) {
    return null;
  } else {
    return longWeekends[next];
  }
}

type Holiday = {
  date: DateTime;
  name: string;
};

const HOLIDAYS: Array<Holiday> = [
  { date: DateTime.fromISO("2022-01-01"), name: "New Year's Day" },
  { date: DateTime.fromISO("2022-01-17"), name: "MLK Jr. day" },
  { date: DateTime.fromISO("2022-05-30"), name: "Memorial day" },
  { date: DateTime.fromISO("2022-07-04"), name: "Independence day" },
  { date: DateTime.fromISO("2022-09-05"), name: "Labor day" },
  { date: DateTime.fromISO("2022-11-11"), name: "Veterans day" },
  { date: DateTime.fromISO("2022-11-25"), name: "Thanksgiving" },
  { date: DateTime.fromISO("2022-12-26"), name: "Christmas day" },
  { date: DateTime.fromISO("2022-12-31"), name: "New Year's Day" },
];

function holidayToLongWeekend(holiday: Holiday): LongWeekend {
  if (holiday.date.weekday == 6) {
    // Saturday
    return {
      start: holiday.date,
      end: holiday.date.plus({ days: 1 }),
      name: holiday.name,
    };
  } else if (holiday.date.weekday == 7) {
    // Sunday
    return {
      start: holiday.date.minus({ days: 1 }),
      end: holiday.date,
      name: holiday.name,
    };
  } else if (holiday.date.weekday == 1) {
    // Monday
    return {
      start: holiday.date.minus({ days: 2 }),
      end: holiday.date,
      name: holiday.name,
    };
  } else if (holiday.date.weekday == 5) {
    // Friday
    return {
      start: holiday.date,
      end: holiday.date.plus({ days: 2 }),
      name: holiday.name,
    };
  } else {
    // Any other day
    return {
      start: holiday.date,
      end: null,
      name: holiday.name,
    };
  }
}
