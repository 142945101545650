import { LongWeekend, nextLongWeekend } from "./Data";
import { DateSpan } from "./DateSpan";
import { Countdown } from "./Countdown";

type CountdownProps = {
  longWeekends: Array<LongWeekend>;
};

export const NextHoliday = ({ longWeekends }: CountdownProps) => {
  const next = nextLongWeekend(longWeekends);
  if (!next) {
    return <h2>We're all done this year!</h2>;
  }
  return (
    <>
      <h2>
        <span className="underline">
          <DateSpan {...next} month="long" />
        </span>
        <br />
        is the next long weekend
      </h2>
      <Countdown next={next} />
    </>
  );
};
