import * as ReactDOM from "react-dom";

import "./index.scss";
import { Calendar } from "./Calendar";
import { NextHoliday } from "./NextHoliday";
import { allLongWeekends } from "./Data";

const longWeekends = allLongWeekends();

ReactDOM.render(
  <NextHoliday longWeekends={longWeekends} />,
  document.querySelector("#CountdownHolder")
);

ReactDOM.render(
  <Calendar longWeekends={longWeekends} />,
  document.querySelector("#HolidaysCalendar")
);
